import { useEffect } from 'react';

export default function WiggleBoomPrivacy() {
    
    useEffect(() => {
        // window.bg.theme = window.bg.bokeh;
        // window.bg.init();
    }, []);

    return (
        <div class="textFull" style={{textAlign: 'left', padding: '32px'}}>
            <h1>Wiggle Boom Privacy Policy</h1>
            <i>Last updated: 28-September-2022</i>
            <p>
                Benjamin M Robinson respects the privacy of his users (“user” or “you”) who install and use Wiggle Boom (the "Application"). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.
            </p>
            <p>
                I reserve the right to make changes to this Privacy Policy at any time and for any reason. I will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.  
            </p>
            <p>
                This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, including any in-game purchases, which may also collect and use data about you. I am not responsible for any of the data collected by any such third party.
            </p>
            <h2>Collection of Data</h2>
            <p>
                Google Firebase Analytics is used to collect general usage data such as frequencey of use and approximate location.
                The application records which challenges you complete in the app and associates this data with a unique device identifier.
            </p>
            <p>
                The application does NOT collect any personally identifiable information about you such as name, age or email address.
            </p>
            <h2>Contact</h2>
            <p>
                If you have any questions about this privacy policy, please email <span>wiggleb</span><span>oom</span>@<span>zimino</span><span>l.com</span>
            </p>
        </div>
    );
}
