import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function About() {
    
    useEffect(() => {
        // window.bg.theme = window.bg.bokeh;
        // window.bg.init();
    }, []);

    return (
        <div style={{textAlign: 'center', padding: '32px 16px'}}>
            <div style={{textAlign: 'left', maxWidth: '600px', fontSize: '100%', display: 'inline-block', color: '#fff', textShadow: '0 0 5px #000'}}>
                <p>
                    Hi, my name is Benjamin M Robinson, and this is my personal website. It is here to house any projects I come up with outside of my regular job (web and app development).
                </p>
                <p>
                    My first published game, <Link to="/wiggleboom">Wiggle Boom</Link> was released in 2016 and
                    I'm currently working on, amoung other things, a game with the working title <Link to="/tanks">Project Tanks</Link>.
                </p>
                {/* <p>
                    <i>What are all these orbs in the background?</i>
                </p>
                <p>
                    Me having some fun with PixiJS. Sorry if your CPU is overheating now...
                </p>
                <p>
                    <i>What does "ziminol" mean?</i>
                </p>
                <p>
                    It sounds like an industrial strength chemical cleaner, but there is a reason. Not a good one, but a reason.
                    Way way back when hotmail was a thing, I couldn't come up with anything that wasn't taken that didnt have a long string of numbers after it.
                    I typed in ziminol, and it worked, and it was unique - so I kept it. But what is it? It is the phrase "my name is" played backwards. If you are really bored you can try recording yourself saying "My name is ziminol", play it backwards, and you will hear the same phrase back - making it a phonetic palindrome. Now you know. And you can never get the minute back you took to read this - sorry about that.
                </p> */}
            </div>
        </div>
    );
}
