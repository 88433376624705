import { useEffect } from 'react';

export default function Tanks() {

    useEffect(() => {
        // window.bg.theme = window.bg.road;
        // window.bg.init();
    }, []);

    return (
        <div className="pageLayout">
            <div className="main">
                <div className="textFull">

                    <div className="textBox" style={{
                        margin: '16px 0px 32px',
                        fontStyle: 'normal',
                        fontSize: '150%',
                        lineHeight: '2em'
                    }}>
                        PROJECT
                        <br/>
                        <span style={{fontSize: '300%'}}>
                            TANKS
                        </span>
                    </div>

                    <div className="textBox">
                    Under development...
                    </div>

                </div>
            </div>
        </div>
    );
}
