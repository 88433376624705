import { useState } from 'react';
import './App.css';
import WiggleBoom from './pages/WiggleBoom';
import SideMenu from './components/SideMenu';
import HeaderBar from './components/HeaderBar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Tanks from './pages/Tanks';
import About from './pages/About';
import WiggleBoomPrivacy from './pages/WiggleBoomPrivacy';

function App() {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <BrowserRouter>
            <SideMenu
                showMenu={showMenu}
                setShowMenu={setShowMenu}
            />
            <div
                id="page"
                style={{
                    marginLeft: showMenu ? '200px' : '0px'
                }}
                onClick={(e) => {
                    if (showMenu) {
                        setShowMenu(false);
                    }
                }}
            >
                <HeaderBar
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                />
                <div className="page-content">
                    <Routes>
                        <Route path="/wiggleboom" element={<WiggleBoom />} />
                        <Route path="/wiggleboom/privacy" element={<WiggleBoomPrivacy />} />
                        <Route path="/tanks" element={<Tanks />} />
                        <Route path="/about" element={<About />} />
                        <Route path="*" element={<WiggleBoom />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
