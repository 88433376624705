import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function WiggleBoom() {

    useEffect(() => {
        // window.bg.theme = window.bg.road;
        // window.bg.init();
    }, []);

    return (
        <div className="pageLayout">
            <div className="main">
                <div className="textFull">
                    <img src="/WiggleBoom/wiggleBoom.png" style={{width: '50%', height: 'auto', margin: '32px auto 16px auto', padding: '0'}} alt="Wiggle Boom"/>
                </div>
                <div className="textFull">

                    <div className="textBox" style={{margin: '16px 0px 32px'}}>
                        Experience one-touch multiplayer action for up to 16 players simultaneously on one device*!
                    </div>

                    <div className="iframeContainer" style={{margin: '16px 0', boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.5)'}}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/s6_XyxQSSL0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video"></iframe>
                    </div>

                    <div className="textBox" style={{margin: '16px 0px 32px'}}>
                        <div style={{display: 'inline-block', width: '80%', fontSize: '80%', fontStyle: 'normal'}}>
                            Each player creates their own button wherever they want around the edge of the screen. As long as you can get one finger onto the screen, you can play!

                            The fun and chaotic gameplay involves wiggling and weaving your car around the map, avoiding lines, dodging projectiles, and cutting off your opponents in their tracks!
                        </div>
                    </div>
                </div>
                <div className="imgBox imgRight">
                    <img
                        src="/WiggleBoom/light01.jpg"
                        style={{width: '100%', height: 'auto', margin: '0', padding: '0'}}
                        alt="Screenshot"
                    />
                </div>
                <div className="textBox textLeft">
                    Collect powerups like the gun, mace, shadow, shield, ice, reset, and turret!
                </div>
                <div className="imgBox imgLeft">
                    <img
                        src="/WiggleBoom/points01.jpg"
                        style={{width: '100%', height: 'auto', margin: '0', padding: '0'}}
                        alt="Screenshot"
                    />
                </div>
                <div className="textBox textRight">
                    Get points for surviving the longest and taking out other players.
                </div>
                <div className="imgBox imgRight">
                    <img
                        src="/WiggleBoom/pyramid02.jpg"
                        style={{width: '100%', height: 'auto', margin: '0', padding: '0'}}
                        alt="Screenshot"
                    />
                </div>
                <div className="textBox textLeft">
                    Premium contains 9 additional maps and 6 extra themes for even more chaotic action!
                </div>
                
                <div className="textFull">
                    <div className="textBox" style={{fontSize: '120%', margin: '64px 0 32px', fontStyle: 'normal'}}>
                    Available on <a href="https://apps.apple.com/us/app/wiggle-boom-multiplayer/id1168873820" target="new">iOS</a>, <a href="https://play.google.com/store/apps/details?id=com.ziminol.WiggleBoom&hl=en" target="new">Android</a>.
                    <p>
                        <a href="https://www.facebook.com/WiggleBoom/" target="new">Facebook Page</a> &#183; <Link to="/wiggleboom/privacy">Privacy Policy</Link> &#183; Email: <span>wiggleboom</span><span>@</span>ziminol<span>.com</span>.
                    </p>
                    </div>
                </div>

                <div className="textFull">
                    <div className="textBox" style={{fontSize: '100%', margin: '32px 0 64px'}}>
                        *Note: The maximum number of simultaneous players is limited by the maximum number of simultaneous touches your device is able to detect.
                    </div>
                </div>

            </div>
        </div>
    );
}
