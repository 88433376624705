import { Link } from "react-router-dom";


const HeaderBar = ({ showMenu, setShowMenu }) => {

    const slideMenu = (e) => {
        setShowMenu(!showMenu);
    };

    return (<>
        <div className="hamburger" onClick={(e) => slideMenu(e)}>
            <img src="/menu_ddd.png" style={{width: '32px'}} alt="Menu"/>
        </div>
        <table className="headerTable">
            <tbody>
                <tr>
                    <td>
                        <a href="/"><div className="headerTitle headerButton">
                            ziminol.com
                        </div></a>
                    </td>
                    <td style={{textAlign: 'right'}}>
                        <div className="headerMenu">
                            
                            <Link to="/wiggleboom">
                                <div className="headerButton">
                                    wiggle boom
                                </div>
                            </Link>
                            <Link to="/tanks">
                                <div className="headerButton">
                                    project tanks
                                </div>
                            </Link>
                            <Link to="/about">
                                <div className="headerButton">
                                    about
                                </div>
                            </Link>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </>);
}

export default HeaderBar;
