import React from 'react';
import { Link } from "react-router-dom";

const SideMenu = ({ showMenu, setShowMenu }) => {

    return (
        <div
            id="sideMenu"
            style={{
                left: showMenu ? '0px' : '-200px'
            }}
        >
            <div style={{height: '42px', textAlign: 'left'}}>
                <div style={{
                    color: '#666',
                    fontSize: '90%',
                    padding: '11px 16px',
                    textTransform: 'uppercase'
                }}>Menu</div>
            </div>
            {/* <a style={{display: 'block'}} href="/wiggleboom"><div className="sideMenuItem">Wiggle Boom</div></a>
            <a style={{display: 'block'}} href="/tanks"><div className="sideMenuItem">Project Tanks</div></a>
            <a style={{display: 'block'}} href="/about"><div className="sideMenuItem">About</div></a> */}
            <Link
                style={{display: 'block'}}
                to="/wiggleboom"
                onClick={(e) => setShowMenu(false)}
            >
                <div className="sideMenuItem">Wiggle Boom</div>
            </Link>
            <Link
                style={{display: 'block'}}
                to="/tanks"
                onClick={(e) => setShowMenu(false)}
            >
                <div className="sideMenuItem">Project Tanks</div>
            </Link>
            <Link
                style={{display: 'block'}}
                to="/about"
                onClick={(e) => setShowMenu(false)}
            >
                <div className="sideMenuItem">About</div>
            </Link>
        </div>
    );
}

export default SideMenu;
